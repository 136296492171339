<template>
  <v-data-table
      :headers="$vuetify.breakpoint.xs ? mobileHeaders : headers"
      :items="productList"
      :options.sync="options"
      :server-items-length="totalProducts"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      class="main-products-table"
      :mobile-breakpoint="600"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getProductsFromApi"
      @update:sort-desc="getProductsFromApi"
      :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader :type="`table-row-divider@${$store.getters['nav/globalItemPerPage']}`" style="width: 100%"></v-skeleton-loader>
    </template>
    <template #[`header.price`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.restricted`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.bsr_avg30`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.created_at`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.profit_fba`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.store`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.buy_box_price`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.fba_offers`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`item.status`]="{item}">
      <v-tooltip open-delay="300" :max-width="300" color="secondary" top v-if="item.status == 2">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            small
            :color="statusColor[status[item.status]]"
            :class="`${statusColor[status[item.status]]}--text`"
            class="v-chip-light-bg"
            v-bind="attrs"
            v-on="on"
          >
            {{ status[item.status] }}
            <v-icon class="ml-1" x-small>{{ icons.mdiInformation }}</v-icon>
          </v-chip>
        </template>
        <span><b>{{ $t('Verification date:')}} </b>{{item.verified_at}}</span>
      </v-tooltip>
      <v-chip
        v-else
        small
        :color="statusColor[status[item.status]]"
        :class="`${statusColor[status[item.status]]}--text`"
        class="v-chip-light-bg"
      >
        {{ status[item.status] }}
      </v-chip>
    </template>
    <template #[`item.keepa_image`]="{item}">
      <router-link :to="{name: 'product', params: {id: item.id}}" class="d-flex justify-center">
        <v-img
          max-height="140"
          max-width="145"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.keepa_image||noKeepaImage"
        />
      </router-link>
    </template>
    <template #[`item.bsr_avg30`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.bsr_avg30" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              small
              label
              v-bind="attrs"
              v-on="on"
              style="min-width: 40px; justify-content: center; max-width: fit-content"
              :color="item.bsr_avg30 > 0? 'success':'error'"
              :class="item.bsr_avg30 > 0? 'success--text':'error--text'"
              class="v-chip-light-bg mb-2"
            >
              {{ item.bsr_avg30 | numberWithSpaces }}
            </v-chip>
          </template>
          <span>{{ '90d avg BSR'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.category" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.category}}
            </span>
          </template>
          <span>{{ 'Category'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.asin`]="{item}">
      <a :href="item.product_url" target="_blank" rel="nofollow" @click.stop="">{{ item.asin }}</a>
    </template>
    <template #[`item.store`]="{item}">
        <v-tooltip v-if="cutString(item.store).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <router-link 
              @click.stop=""
              :to="{name: 'store-products', params: {slug: item.store_slug}}"
            >
              <span
                v-bind="attrs"
                v-on="on"
                style="white-space: nowrap"
              >
                {{ cutString(item.store) }}
              </span>
            </router-link>
          </template>
          <span>{{ item.store }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
              <router-link 
                v-bind="attrs"
                v-on="on"
                @click.stop=""
                :to="{name: 'store-products',params:{slug: item.store_slug}}"
              >
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ cutString(item.store) }}
                </span>
              </router-link>
          </template>
          <span>{{ 'Store' }}</span>
        </v-tooltip>
        <div class="d-flex align-center justify-center my-1">
          <rating :id-rating="item.store_rating_id" />
        </div>
        <v-tooltip v-if="cutString(item.source_link).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <div 
              v-bind="attrs"
              v-on="on"
            >
              <a 
                :href="'https://'+item.source_link"
                target="_blank"
                rel="nofollow"
                @click.stop=""
              >
                {{ cutString(item.source_link) }}
              </a>
            </div>
          </template>
          <span>{{ item.source_link }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <div 
              v-bind="attrs"
              v-on="on"
            >
              <a 
                :href="'https://'+item.source_link"
                target="_blank"
                rel="nofollow"
                @click.stop=""
              >
                {{ cutString(item.source_link) }}
              </a>
            </div>
          </template>
          <span>{{ 'Source'}}</span>
        </v-tooltip>
    </template>
    <template #[`item.created_at`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="mb-2"
          >
            {{ item.created_at }}
          </div>
        </template>
        <span>{{ 'Created'}}</span>
      </v-tooltip>
      <v-tooltip open-delay="300" :max-width="300" color="secondary" top v-if="item.status == 2">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
              small
              :color="statusColor[status[item.status]]"
              :class="`${statusColor[status[item.status]]}--text`"
              class="v-chip-light-bg"
              v-bind="attrs"
              v-on="on"
          >
            {{ status[item.status] }}
            <v-icon class="ml-1" x-small>{{ icons.mdiInformation }}</v-icon>
          </v-chip>
        </template>
        <span><b>{{ $t('Verification date:')}} </b>{{item.verified_at}}</span>
      </v-tooltip>
      <v-chip
        v-else
        small
        :color="statusColor[status[item.status]]"
        :class="`${statusColor[status[item.status]]}--text`"
        class="v-chip-light-bg"
      >
        {{ status[item.status] }}
      </v-chip>
    </template>
    <template #[`item.price`]="{item}">
      <v-list-item class="font-weight-semibold">{{ `$${Number(item.price)}` }}</v-list-item>
    </template>
    <template #[`item.buy_box_price`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ Number(item.buy_box_price) ? cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : $t("No BuyBox") }}</span>
          </template>
          <span>{{ 'BuyBox price'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>COG:</b> {{ cCodeToSymbol[item.currency_code] + Number(item.cog) }}</span>
          </template>
          <span>{{ 'Cost of goods'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>3PL:</b> {{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}</span>
          </template>
          <span>{{ 'Third-party logistics'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.profit_fba`]="{item}">
      <div class="d-flex flex-column align-start">
        <v-tooltip v-if="item.profit_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small label
              color="success"
              class="v-chip-light-bg success--text mb-2"
            >
              {{ Number(item.profit_fba) ? cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-' }}
            </v-chip>
          </template>
          <span>{{ 'Profit'}}</span>
        </v-tooltip>
        <v-tooltip v-if="item.roi_fba" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ Number(item.roi_fba) }}%
            </span>
          </template>
          <span>{{ 'ROI'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.restricted`]="{item}">
      <div class="d-flex flex-column align-center">
        <div class="mb-1 d-flex justify-center">
          <red-flags
              style="display: contents"
              :hasVariations="!!item.has_variations"
              :bundle="!!item.bundle"
              :isFragile="!!item.is_fragile"
              :isWater="!!item.is_meltable"
              :isBiohazard="!!item.is_haz_mat"
              :isPackage="!!item.is_oversize"
              :isAlert="!!item.is_alert"
              :isAmazon="!!item.is_amazon"
              :isAdult="!!item.is_adult"
              :isSmallAndLigth="!!item.is_small_and_ligth"
          />
        </div>
        <v-btn
          v-if="$route.meta.user === 'unsigned'"
          block
          small
          color="primary"
          class="my-2 mx-0"
          @click.stop="$router.push({ name: 'auth-login'})"
        >
          {{ $t('login') }}
        </v-btn>
        <div 
          v-else-if="$route.meta.user === 'signed' && connectedAmzAccByRegions && marketplaceByRegion"
          class="text-center"
        >
          <v-btn
            color="success"
            outlined
            small
            @click.stop=""
            :to="{name: 'user-account', params: {tab: 'amazon-account'}}"
            v-if="!connectedAmzAccByRegions[marketplaceByRegion[item.marketplace_id]]"
          >
            {{ $t('check') }}
          </v-btn>
          <v-progress-circular
            v-else-if="item.restriction_data === false"
            indeterminate
            color="primary"
            size="20"
            width="3"
          />
          <v-tooltip v-else-if="item.restriction_data && item.restriction_data.reasons" top color="secondary" open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="rgb(177, 39, 4)"
                small
              >
                {{ icons.mdiLock }}
              </v-icon>
            </template>
            <span>{{ 'You need approval'}}</span>
          </v-tooltip>
          <v-tooltip v-else-if="item.restriction_data && !item.restriction_data.error" top color="secondary" open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="success"
                small
              >
                {{ icons.mdiLockOpenVariant }}
              </v-icon>
            </template>
            <span>{{ 'You can sell this product'}}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template #[`item.fba_offers`]="{item}">
      <div class="d-flex flex-column align-start">
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              color="rgba(19, 156, 224, 0.3)"
              text-color="#EC6337"
              class="mb-2 deals-chip-offers fbm"
            >
              <b>FBM:{{ Number(item.fbm_offers) }}</b>
            </v-chip>
          </template>
          <span>{{ 'FBM quantity'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              small
              label
              class="deals-chip-offers fba"
              color="rgba(236, 99, 55, 0.3)"
              text-color="#EC6337"
            >
              <b>FBA:{{ Number(item.fba_offers) }}</b>
            </v-chip>
          </template>
          <span>{{ 'FBA quantity'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.id`]="{item}">
      <router-link :to="{name: 'product', params:{id: item.id}}">{{ item.id }}</router-link>
    </template>
    <template #[`item.eye`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{ name: 'product', params: { id: item.id } }"
            >
              <v-icon size="26">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ 'View'}}</span>
        </v-tooltip>
    </template>
    <template #[`item.access`]="{item}">
        <div>
          <v-btn
            block
            outlined
            small
            color="primary"
            class="my-2 mx-0"
            @click.stop="addToCart(item.id)"
            :disabled="productsIdsInCart.indexOf(item.id) !== -1"
          >
            {{ $t('add to cart') }}
          </v-btn>
          <v-btn block small color="primary" class="my-2 mx-0" @click.stop="buyNow(item.id)">{{ $t('buy now') }}</v-btn>
        </div>
    </template>
    <template #[`item.mobile`]="{item}">
        <div>
          <router-link :to="{name: 'product', params: {id: item.id}}" class="d-flex justify-center mt-2">
            <v-img
              width="250"
              style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
              :src="item.keepa_image || noKeepaImage"
            />
          </router-link>
          <div class="d-flex pt-2 flex-column px-2">
            <div class="d-flex my-1">
              <div class="mb-1 d-flex justify-center">
                <red-flags
                    style="display: contents"
                    :hasVariations="!!item.has_variations"
                    :bundle="!!item.bundle"
                    :isFragile="!!item.is_fragile"
                    :isWater="!!item.is_meltable"
                    :isBiohazard="!!item.is_haz_mat"
                    :isPackage="!!item.is_oversize"
                    :isAlert="!!item.is_alert"
                    :isAmazon="!!item.is_amazon"
                    :isAdult="!!item.is_adult"
                    :isSmallAndLigth="!!item.is_small_and_ligth"
                />
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex my-1">
              <span><b>ID:</b></span><router-link class="ml-2" :to="{name: 'product', params:{id: item.id}}">{{ item.id }}</router-link>
            </div>
            <div class="d-flex my-1">
              <span><b>BSR:</b></span>
                <v-chip
                  small
                  label
                  style="min-width: 40px; justify-content: center; max-width: fit-content"
                  :color="item.bsr_avg30 > 0? 'success':'error'"
                  :class="item.bsr_avg30 > 0? 'success--text':'error--text'"
                  class="v-chip-light-bg ml-2"
                >
                  {{ item.bsr_avg30 | numberWithSpaces }}
                </v-chip>
            </div>
            <div class="d-flex my-1">
              <v-tooltip top color="secondary" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    label
                    color="rgba(19, 156, 224, 0.3)"
                    text-color="#EC6337"
                    class="deals-chip-offers fbm"
                  >
                    <b>FBM:{{ Number(item.fbm_offers) }}</b>
                  </v-chip>
                </template>
                <span>{{ 'FBM quantity'}}</span>
              </v-tooltip>
              <v-tooltip top color="secondary" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    label
                    class="deals-chip-offers fba ml-2"
                    color="rgba(236, 99, 55, 0.3)"
                    text-color="#EC6337"
                  >
                    <b>FBA:{{ Number(item.fba_offers) }}</b>
                  </v-chip>
                </template>
                <span>{{ 'FBA quantity'}}</span>
              </v-tooltip>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Source') }}:</b></span>
              <a
                :href="'https://'+item.source_link"
                target="_blank"
                rel="nofollow"
                @click.stop=""
                class="ml-2"
              >
                {{ item.source_link }}
              </a>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Store') }}:</b></span>
              <router-link
                class="ml-2"
                @click.stop=""
                :to="{name: 'store-products',params:{slug: item.store_slug}}"
              >
                <span>
                  {{ item.store }}
                </span>
              </router-link>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Store rating') }}:</b></span><span class="ml-2"><rating :id-rating="item.store_rating_id" /></span>
            </div>
            <div class="d-flex my-1">
              <span><b>BuyBox price:</b></span><span class="ml-2">{{ Number(item.buy_box_price) ? cCodeToSymbol[item.currency_code] + Number(item.buy_box_price) : $t("No BuyBox") }}</span>
            </div>
            <div class="d-flex my-1">
              <span><b>Cost of Goods:</b></span><span class="ml-2">{{ cCodeToSymbol[item.currency_code] + Number(item.cog) }}</span>
            </div>
            <div class="d-flex my-1">
              <span><b>Third-party logistics:</b></span><span class="ml-2">{{ Number(item.third_pl) ? cCodeToSymbol[item.currency_code] + Number(item.third_pl) : '-' }}</span>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Profit') }}:</b></span>
              <v-chip
                small label
                color="success"
                class="v-chip-light-bg success--text ml-2"
              >
                {{ Number(item.profit_fba) ? cCodeToSymbol[item.currency_code] + Number(item.profit_fba) : '-' }}
              </v-chip>
            </div>
            <div class="d-flex my-1">
              <span><b>ROI:</b></span><span class="ml-2 success--text">{{ Number(item.roi_fba) }}%</span>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Status') }}:</b></span>
              <v-chip
                  small
                  :color="statusColor[status[item.status]]"
                  :class="`${statusColor[status[item.status]]}--text`"
                  class="v-chip-light-bg ml-2"
              >
                {{ status[item.status] }}
                <v-icon class="ml-1" x-small>{{ icons.mdiInformation }}</v-icon>
              </v-chip>
            </div>
            <div class="d-flex my-1">
              <span><b>{{ $t('Created') }}:</b></span><span class="ml-2">{{ item.created_at }}</span>
            </div>
            <div class="d-flex justify-space-between mb-4 mt-2">
              <v-btn
                color="primary"
                outlined
                class=""
                style="width: 44%"
                @click.stop="addToCart(item.id)"
                :disabled="productsIdsInCart.indexOf(item.id) !== -1"
              >
                {{ $t('add to cart') }}
              </v-btn>
              <v-btn
                style="width: 44%"
                color="primary"
                @click.stop="buyNow(item.id)"
              >
                {{ $t('buy now') }}
              </v-btn>
            </div>
          </div>
        </div>
    </template>
  </v-data-table>
</template>

<script>
import {mdiEyeOutline, mdiSquareEditOutline, mdiDotsVertical, mdiMenuDown, mdiLock, mdiLockOpenVariant, mdiInformation} from '@mdi/js'
import {computed, ref, watch, getCurrentInstance} from "@vue/composition-api";
import axios from "@axios";
import RedFlags from "./components/RedFlags";
import Rating from "./components/Rating";
import {useRouter} from "@core/utils";
import store from "@/store";
import {numberWithSpaces, kitCut, cCodeToSymbol} from "@/plugins/helpers"
import {buyNowClicked, productAddedToCart} from "@/plugins/tracking/events";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  components: {
    RedFlags,
    Rating
  },
  filters: {
    numberWithSpaces
  },
  props: ['storeId', 'updateSearch', 'loadConnected'],
  setup(props) {
    const { menuIsVerticalNavMini } = useAppConfig()
    const statusColor = {
      Verified: 'success',
      Unverified: 'warning',
    }
    const vm = getCurrentInstance().proxy
    const {router, route} = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.store;
      },
      set(val) {
        let page = val.page > val.lastPage ? 1 : val.page
        store.commit("pagination/SET_STORE_PAGINATION", Object.assign({}, val, {
          page
        }))
      }
    }))
    const loading = ref(true)
    const products = ref([])
    const totalProducts = ref(0)
    const addToCart = (id) => {
      let event = productAddedToCart(id)
      logEventAmplitude(event.name, event.props)
      logEventMixPanel(event.name, event.props)
      return store.dispatch('cart/setNewIdToCart', {id})
    }
    const buyNow = (id) => {
      let eventProductAdded = productAddedToCart(id)
      logEventAmplitude(eventProductAdded.name, eventProductAdded.props)
      logEventMixPanel(eventProductAdded.name, eventProductAdded.props)
      logEventAmplitude(buyNowClicked.name)
      logEventMixPanel(buyNowClicked.name)
      if (route.value.meta.user === 'signed') {
        addToCart(id).then(() => {
          store.commit('cart/SET_BUY_NOW_ID', id)
          router.push({name: 'checkout'})
        })
      } else if (route.value.meta.user === 'unsigned') {
        addToCart(id).then(() => router.push({name: 'auth-login', query: {route: 'checkout'}}))
      }
    }
    const productsIdsInCart = computed(() => store.state.cart.cartIds)
    const restrictions = computed(() => store.state.product.restrictions)
    watch(() => restrictions.value, () => {
      restrictions.value.forEach((restriction) => {
        let productIndex = products.value.findIndex(el => el.id === restriction.id)
        if (productIndex !== -1)
          products.value[productIndex] = Object.assign(products.value[productIndex], {'restriction_data': restriction.data})
      })
    })
    watch(() => store.state.filter.updateFilter, () => getProductsFromApi())
    watch(() => loading.value, (newValue) => vm.$emit('loading', newValue))
    watch(() => props.loadConnected, async () => {
      if(route.value.name != 'store-products') await store.dispatch("filter/getFilterData")       
      getProductsFromApi()
    })
    watch(() => props.updateSearch, (newValue) => getProductsFromApi())
    const cutString = (text, limit = menuIsVerticalNavMini.value ? 22 : 14) => kitCut(text, limit)
    const getProductsFromApi = () => {
      loading.value = true
      const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
      let uri = 'products'
      let params = {
        pagination: globalItemsPerPage.value,
        page,
        sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
        sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
        storeId: props.storeId
      }

      if(store.getters['filter/getFilterDataString'] !== 'null') {
        params = Object.assign({}, params, {
          filter: store.getters['filter/getFilterDataString']
        })
      } 

      if (route.value.meta.user === 'unsigned') uri = 'products-unsigned'
      axios.get(uri, {
        params
      }).then(res => {
        products.value = res.data.data.map(e => Object.assign(e, {access: '', restricted: ''}))
        totalProducts.value = res.data.meta.total
        loading.value = false
        store.commit("pagination/SET_STORE_PAGINATION", {
          itemsPerPage: globalItemsPerPage.value,
          page,
          lastPage: res.data.meta.last_page
        })
      }).catch(e => console.log(e))
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])

    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
      getProductsFromApi()
    }

    if(props.loadConnected === undefined) getProductsFromApi()

    return {
      cutString,
      updateItemsPerPage,
      globalItemsPerPage,
      getProductsFromApi,
      addToCart,
      buyNow,
      marketplaceByRegion: computed(() => store.state.connect.marketplaceByRegion),
      connectedAmzAccByRegions: computed(() => store.state.connect.connectedAmzAccByRegions),
      options,
      loading,
      totalProducts,
      productsIdsInCart,
      mobileHeaders: [
        {value: 'mobile', sortable: false},
      ],
      headers: [
        {text: 'ID', value: 'id', align: 'center'},
        {text: 'Keepa', value: 'keepa_image', sortable: false, align: 'center'},
        // {text: 'Category', value: 'category', width: 110},
        {text: '30d avg BSR<br>Category', value: 'bsr_avg30', width: 120, sortable: false},
        // {text: '30d BSR Drops', value: 'sales_rank_drops30', width: 100},
        // {text: 'ROI', value: 'roi_fba', width: 80},
        {text: 'Profit<br>ROI', value: 'profit_fba', sortable: false},
        {text: 'FBA/FBM<br>Sellers Qty', value: 'fba_offers', width: 80, sortable: false},
        // {text: 'Sales est', value: 'sales_est', width: 120},
        {text: 'BuyBox Price<br>COG&nbsp&nbsp3PL', value: 'buy_box_price', width: 95, sortable: false},
        // {text: 'COG', value: 'cog', width: 80},
        // {text: '3PL', value: 'third_pl', width: 80},
        // {text: 'Amazon Fees', value: 'fba_fee', width: 130},
        // {text: 'Red Flags', value: 'is_fragile', width: 120, sortable: false},
        {text: 'Red Flags<br>Restrictions', value: 'restricted',  sortable: false, align: 'center'},
        {text: 'Store<br>Source', value: 'store',  sortable: false, align: 'center'},
        // {text: 'Source', value: 'source_link', sortable: false},
        {text: '<div class="ml-4 d-inline-block align-center justify-content-center">Created Date<br>Deal\'s Status</div>', value: 'created_at', align: 'center', width: 120},
        // {text: 'Status', value: 'status', width: 90, align: 'center'},
        {text: '<div class="ml-4 d-inline-block align-center justify-content-center">Deal<br>Price</div>', value: 'price', align: 'center', width: 80},
        {value: 'eye', sortable: false, align: 'end'},
        {value: 'access', sortable: false, width: 80}

      ],
      productList: products,
      status: {
        0: 'Draft',
        1: 'Unverified',
        2: 'Verified',
        3: 'Rejected',
        4: 'Banned',
      },
      statusColor,
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
      icons: {
        mdiEyeOutline,
        mdiInformation,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMenuDown,
        mdiLock,
        mdiLockOpenVariant
      },
      cCodeToSymbol: cCodeToSymbol()
    }
  },
}
</script>

<style lang="scss">
.main-products-table {
  a {
    text-decoration: none;
  }
  th.text-end, td.text-end {
    max-width: 38px
  }
  @media only screen and (max-width: 1215px) {
    td:last-child,
    th:last-child,
    th:nth-last-child(2),
    td:nth-last-child(2),
    th:nth-last-child(3),
    td:nth-last-child(3) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 1;
      background-color: white;
    }

    th:nth-last-child(2),
    td:nth-last-child(2) {
      right: 124px;
      padding-right: 2px !important;
    }
    th:nth-last-child(3),
    td:nth-last-child(3) {
      right: 162px;
      padding-left: 10px !important;
    }

    td:last-child,
    th:last-child {
      right: 0;
      padding: 0 !important;
      padding-right: 5px !important;
    }

    td:nth-last-child(3) {
      box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: -5px 0px 5px 1px rgba(0, 0, 0, 0.05);
    }
  }
}

.theme--dark {
  @media only screen and (max-width: 960px) {
    .main-products-table {
      td:last-child,
      th:last-child,
      th:nth-last-child(2),
      td:nth-last-child(2) {
        background-color: #302d4b;
      }
    }
  }
}
</style>