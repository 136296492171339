<template>
    <v-tooltip
        v-if="ratings[idRating]"
        max-width="250"
        min-width="250"
        top
        color="secondary"
        open-delay="300"
    >
        <template v-slot:activator="{ on, attrs }">
            <span 
                v-bind="attrs"
                v-on="on"
                class="deals-rating"
                :class="classes"
            >
                <span class="rating-text">
                    {{ ratings[idRating] }}
                </span>
            </span>
        </template>
        <span><b>{{ $t('Seller Rating: ') + capitalize(ratings[idRating]) }}</b></span></br>
        <span>{{ $t('The seller\'s rating is calculated based on the quality of their deals and their buyer\'s satisfaction.') }}</span>
    </v-tooltip>
</template>

<script>
import {computed} from "@vue/composition-api";

export default {
    props: {
        idRating: { 
            type: Number,
            default: 4
        },
        card: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const capitalize = (s) => s[0].toUpperCase() + s.slice(1)
        const ratings = {
            1: 'gold',
            2: 'silver',
            3: 'bronze',
            4: 'wood',
        }
        return {
            classes: computed(() => props.card ? [ratings[props.idRating], 'card'] : ratings[props.idRating]),
            capitalize,
            ratings,
        }
    }
}
</script>